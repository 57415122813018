import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Container from '../components/container'
import CustomCarousel from '../components/carousel'
import { Helmet } from 'react-helmet'

export default function History() {
    const navigation = [
        { name: 'На головну', href: '/', key: 27 },
    ]

    const slides = [
        { name: 'bg.jpg', key: 28 },
        { name: 'bg.jpg', key: 29 },
        { name: 'bg.jpg', key: 30 },
        { name: 'bg.jpg', key: 34 }
    ]

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Георгій Дем’янович каже: «Я виробляю таку продукцію, яку можу спокійно давати власним дітям та онукам», а його діти та онуки підтверджують, що продукція ТМ Дем’янович не просто якісна, а й дуже смачна.
                            «Херсонський підприємець Червєнков Георгій Дем’янович свою трудову діяльність розпочав ще у 1986 році на Херсонському машинобудівному заводі ім. Петровського, де працював слюсарем-бригадиром механозбірних робіт. Після цього працював у сфері оптової та роздрібної торгівлі.
                            У 2002 році він заснував власну компанію, яка займалася оптовою торгівлею консервації, бакалійної продукції під власною торговою маркою «Дем'янович», виробництвом продукції під індивідуальні замовлення, а також торгівлею широким асортиментом склотари для промислового виробництва та побутових потреб.
                            У розвиток бізнесу молодим підприємцем було вкладено багато зусиль та любові.  Георгій Дем’янович використовує свої унікальні знання для відбору найкращої продукції." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Історія заводу</title>
                <meta name="theme-color" content="#53C82A" />
                <link rel="apple-touch-icon" href="logo.png"></link>
            </Helmet>
            <div className="bg-hero-image bg-no-repeat bg-cover bg-top">
                <Container>
                    <Navbar navigation={navigation} />
                </Container>
            </div>

            <Container>
                <div className="container mx-auto flex mt-8 lg:mt-12 md:flex-row flex-col items-center">
                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 md:mb-0">
                        <CustomCarousel slides={slides.map(item => <img src={item.name} alt="Історія заводу ТМ Дем'янович" />)} />
                    </div>
                    <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 pt-12 md:pt-0 flex flex-col md:items-start items-center" bis_skin_checked="1">
                        <h2 className="text-3xl sm:text-4xl md:text-5xl mb-4 font-semibold text-gray-900">Історія заводу</h2>
                        <p className="leading-relaxed">
                            Георгій Дем’янович каже: «Я виробляю таку продукцію, яку можу спокійно давати власним дітям та онукам», а його діти та онуки підтверджують, що продукція ТМ Дем’янович не просто якісна, а й дуже смачна.
                            «Херсонський підприємець Червєнков Георгій Дем’янович свою трудову діяльність розпочав ще у 1986 році на Херсонському машинобудівному заводі ім. Петровського, де працював слюсарем-бригадиром механозбірних робіт. Після цього працював у сфері оптової та роздрібної торгівлі.
                            У 2002 році він заснував власну компанію, яка займалася оптовою торгівлею консервації, бакалійної продукції під власною торговою маркою «Дем'янович», виробництвом продукції під індивідуальні замовлення, а також торгівлею широким асортиментом склотари для промислового виробництва та побутових потреб.
                            У розвиток бізнесу молодим підприємцем було вкладено багато зусиль та любові.  Георгій Дем’янович використовує свої унікальні знання для відбору найкращої продукції.
                        </p>
                    </div>
                </div>
                <div className="container mx-auto">
                    <p className="leading-relaxed md:mt-16 md:text-left">
                        Вже у 2014 році було придбано консервний завод, який на той час був напівзруйнований. Завдяки наполегливій роботі Георгій Дем’яновича, його сім’ї та професійної команди, завод відновлено, введено в експлуатацію. Наразі підприємство спеціалізується на виготовленні соків та плодоовочевій консервації, оптовій торгівлі склотари.
                        Для мешканців с. Посад-Покровське та Херсонської області створено нові робочі місця. Завдяки командній роботі на консервному заводі відбуваються позитивні зміни, проводиться оптимізація виробництва та праці.
                        Компанія турбується про якість та смак виготовленої продукції, впровадження новітніх технологій на виробництві. Підтвердженням якості продукції є впровадження системи харчової безпеки НАССР, контроль сировини з якої виготовляється продукція, матеріалів упаковки, доставки готової продукції споживачам. Постачальниками сільськогосподарської продукції для заводу є найкращі українські фермери.
                        Консервація торгової марки «Дем'янович» проводиться за класичною технологією – без використання синтетичних консервантів, барвників, ароматизаторів, ГМО.
                        Важливу роль відіграє і використання у виробництві найбільш екологічної тари – скляної банки.
                        Затребуваними товарами є «Сік яблучний», «Сік виноградний», «Сік персиковий», «Сік гранатовий», «Сік томатний», «Сік березовий з цукром», «Сік березовий з часточками лимона».
                        Крім соків, підприємство успішно реалізує: консервовані огірки та помідори, зелений горошок, томатну пасту, квасолю в томатному і ніжному соусі, різні салати та інші види продукції. ТМ «Дем’янович» включає в себе також м’ясну групу (представлену різними видами тушонки) і солодку групу (повидло, карамелізоване молоко).

                    </p>
                </div>
            </Container>

            <div className="bg-green-400">
                <Container>
                    <Footer />
                </Container>
            </div>
        </div>
    )
}
